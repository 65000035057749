<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 md12>
      <v-card>
        <title-card title="Caracteristicas" subtitle="Rellena los campos posibles"></title-card>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap justify-space-between class="my-0">
            <template v-for="(feature, i) in features">
              <v-flex v-if="feature.type === 0" :key="i" xs12 sm6 md12 lg3 pt-0 pb-0>
                <v-text-field v-model="features[i].input" :value="0" color="secondary" :label="feature.name">
                </v-text-field>
              </v-flex>
              <v-flex v-if="feature.type === 1" :key="i" xs12 sm6 md12 lg3 pt-0 pb-0>
                <v-select
                  v-model="features[i].input"
                  :items="feature.values"
                  color="secondary"
                  item-text="value"
                  item-value="id"
                  :label="feature.name"
                  :placeholder="feature.name"
                  class="input-group--focused"
                ></v-select>
              </v-flex>
            </template>
            <v-flex xs12 sm6 md12 lg3 pt-0 pb-0>
              <v-switch
                v-model="product.elaboracion"
                color="secondary"
                label="El producto requiere elaboracion?"
                hide-details
              ></v-switch>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="save"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { GET_PRODUCT, GET_FEATURES } from '../../config'
import titleCard from '../useful/titleCard.vue'

export default {
  name: 'ProductSeo',
  components: { titleCard },
  props: ['product', 'getProduct'],
  data() {
    return {
      loading: false,
      features: []
    }
  },
  watch: {
    product() {
      this.initComponent()
    }
  },
  mounted() {
    this.initComponent()
  },
  methods: {
    async getFeatures() {
      try {
        const res = await this.$http.get(`${GET_FEATURES}`)
        this.features = res.data
        if (this.product.features.length > 0) {
          let key = 0
          let value = ''
          this._.forEach(this.product.features, item => {
            key = this._.findKey(this.features, ['id', item.feature_id])
            value = item.feature_value_id
            if (this.features[key].type === 0) {
              value = item.feature_value
            }
            this.features[key].input = value
          })
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async save() {
      this.loading = true
      try {
        await this.$http.put(`${GET_PRODUCT}/${this.product.id}/feature`, {
          features: this.features,
          elaboracion: this.product.elaboracion
        })
        this.getProduct()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    initComponent() {
      this.getFeatures()
    }
  }
}
</script>
